.main-header {
	width: 100%;
	z-index: 99;
	position: fixed;
	top: 0;
	background: #fff;
	box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
	// transition: all 0.4s ease;
}

.header-desktop {
	height: 116px;
	transition: height 0.2s ease;
	@include respond-to('medium') {
		display: none;
	}
	& .section-limit {
		position: relative;
	}
}

.logo {
	position: absolute;
	#{$left}: 15px;
	top: 30px;
	z-index: 1;
	// height: 100%;
	pointer-events: all;
	transition: all 0.2s ease;
	@include respond-to('1100') {
		top: 35px;
	}
	& a {
		display: block;
		padding: side-values(0 25px 0 0);
		background-color: #fff;
		transition: all 0.2s ease;
	}
	& img {
		// height: 51px;
		// margin-top: 10px;
		transition: all 0.2s ease;
		@include respond-to('1100') {
			max-width: 170px;
		}
	}
}

.sub-nav {
	height: 57px;
	border-bottom: 1px solid #e1e1e1;
	transition: all 0.2s ease;
	& > ul {
		display: inline-block;
		padding: 15px 0 0;
		margin: 0;
		list-style: none;
		transition: all 0.2s ease;
		@include respond-to('1100') {
			padding-top: 19px;
		}
		& > li {
			margin: 0 12px;
			display: inline-block;
			vertical-align: middle;
			color: #000;
			@include respond-to('large') {
				margin: 0 7px;
			}
			&:first-child {
				margin-#{$right}: 0;
			}
			& > a {
				display: inline-block;
				padding-bottom: 10px;
				border-bottom: 3px solid transparent;
				vertical-align: middle;
				@include link_no-hover(#000);
				@include respond-to('1100') {
					font-size: 18px;
					// padding: 6px 1px 13px;
				}
				&:hover {
					text-decoration: none;
					border-bottom: 3px solid $accent;
				}
				&:focus,
				&:active {
					text-decoration: none;
				}
				&.active {
					border-bottom: 3px solid $accent;
				}
			}
			& i.fa-search {
				font-size: 20px;
				color: #477f29;
			}
		}
	}
}

.header-cart {
	float: $left;
	margin-#{$left}: 25px;
	margin-top: 13px;
	@include respond-to('large') {
		margin-#{$left}: 20px;
	}
	@include respond-to('1100') {
		margin-#{$left}: 10px;
	}
}

.sub-phone {
	float: $left;
	margin-#{$left}: 50px;
	margin-top: 20px;
	line-height: 1;
	vertical-align: bottom;
	@include respond-to('large') {
		margin-#{$left}: 20px;
	}
	@include respond-to('1100') {
		margin-top: 23px;
	}
	& span {
		display: inline-block;
		margin-#{$left}: 8px;
		vertical-align: bottom;
		line-height: 1;
		font-size: 18px;
		color: #000;
	}
	& a {
		display: inline-block;
		font-size: 22px;
		font-weight: 900;
		color: #000;
		line-height: 1;
		@include link_no-hover($accent);
		@include respond-to('1100') {
			font-size: 18px;
		}
	}
	& i {
		display: inline-block;
		margin-#{$right}: 8px;
		color: $accent;
		font-size: 18px;
	}
}

.find-last {
	// display: inline-block;
	float: $left;
	height: 100%;
	vertical-align: top;
	margin-#{$left}: 275px;
	@include respond-to('larger') {
		margin-#{$left}: 250px;
	}
	@include respond-to('large') {
		margin-#{$left}: 230px;
	}
	@include respond-to('1100') {
		margin-#{$left}: 195px;
	}
	&_mobile {
		margin-#{$left}: 0;
		float: none;
		& .search {
			width: 100%;
			& input {
				color: #fff;
				&::placeholder {
					color: #fff;
				}
			}
			& i {
				font-size: 20px;
			}
		}
	}
}

.search {
	position: relative;
	width: 152px;
	// @include respond-to('large') {
	// 	width: 170px;
	// }
	& input {
		width: 100%;
		border: none;
		border-bottom: 1px solid $accent;
		padding: side-values(22px 0 12px 25px);
		font-size: 16px;
		font-weight: 300;
		text-indent: 5px;
		background-color: transparent;
		color: #949494;
		transition: all 0.2s ease;
		&::placeholder {
			font-size: 16px;
			font-weight: 300;
			color: #949494;
		}
	}
	& button {
		position: absolute;
		top: 18px;
		#{$left}: 0;
		border: none;
		background: none;
		transition: all 0.2s ease;
		& i {
			color: $accent;
			font-size: 16px;
		}
	}
}

.main-navi {
	display: block;
	padding: 0 0 0;
	transition: padding 0.2s ease;
	& > ul {
		display: inline-block;
		vertical-align: top;
		list-style: none;
		padding: 0;
		margin: 0;
		padding-top: 12px;
		transition: all 0.2s ease;
		& > li {
			float: right;
			margin-#{$left}: 25px;
			position: relative;
			@include respond-to('large') {
				margin-#{$left}: 14px;
			}
			&:first-child {
				& a {
					padding-#{$right}: 0;
				}
			}
			& > a {
				display: block;
				padding: 3px 1px 13px;
				font-size: 20px;
				font-weight: 400;
				color: #000;
				border-bottom: 3px solid transparent;
				transition: all 0.2s ease;
				@include respond-to('1100') {
					font-size: 18px;
					padding: 6px 1px 13px;
				}
				&:hover {
					text-decoration: none;
					border-bottom: 3px solid $accent;
				}
				&:focus,
				&:active {
					text-decoration: none;
				}
				&.active {
					border-bottom: 3px solid $accent;
					// color: $accent;
				}
			}
		}
	}
}

.header-mobile {
	display: none;
	height: 70px;
	@include respond-to('medium') {
		display: block;
	}
	&__logo {
		margin-#{$left}: 15px;
		margin-top: 10px;
		float: $left;
		& img {
			width: 180px;
		}
	}
	&__tel {
		display: inline-block;
		margin-top: 19px;
		i {
			color: $accent;
			font-size: 32px;
		}
	}
}

.mobile-menu-btn {
	margin-top: 20px;
}

.header-desktop-top-shopcart{
  position: relative;
  & .fa-shopping-cart{
    color: #000;
  }
  &__circle{
    position: absolute;
    top: -4px;
    #{$right}: -13px;
    background: $accent;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    & > span{
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
      line-height: 1;
    }
  }
}