.cat-nav {
	height: 200px;
	width: 100%;
	font-size: 0;
	background-color: rgba($color: #fff, $alpha: 0.4);
	@include respond-to('large') {
		// height: 160px;
	}
	@include respond-to('small') {
		// height: auto;
		text-align: center;
	}
	& .section-limit {
		height: 100%;
	}
	&__slider {
		.slick-prev {
			right: -20px;
			@include respond-to('larger') {
				right: -10px;
			}
			&::before {
				color: $accent;
			}
		}
		.slick-next {
			left: -20px;
			@include respond-to('larger') {
				left: -10px;
			}
			&::before {
				color: $accent;
			}
		}
	}
	&__item {
		width: 11.11%;
		height: 100%;
		height: 200px;
		display: inline-block;
		vertical-align: top;
		font-size: initial;
		overflow: hidden;
		@include respond-to('small') {
			width: 33.33%;
			// height: 165px;
		}
		@include respond-to('extra-small') {
			// width: 50%;
			// height: 112px;
			// &:last-of-type {
			// 	width: 100%;
			// }
		}
	}
}

.cat-item {
	padding: 40px 10px 20px;
	text-align: center;
	transition: background 0.3s ease-in;
	@include respond-to('extra-small') {
		// padding: 10px;
	}
	&:hover,
	&:focus,
	&:active {
		background-color: rgba($color: $accent-g, $alpha: 0.8);
		text-decoration: none;
		& .cat-item__title {
			color: #fff;
		}
		& img {
			filter: invert(100%);
			transition: filter 0.2s ease;
		}
	}
	&__icon {
		height: 70px;
		position: relative;
		@include respond-to('large') {
			// height: 75px;
		}
		@include respond-to('extra-small') {
			// height: 55px;
		}
		& img {
			max-height: 100%;
			// max-width: 85px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	&__title {
		margin-top: 20px;
		font-size: 20px;
		font-weight: 800;
		line-height: 1;
		color: #000;
		transition: color 0.2s ease;
		@include respond-to('large') {
			// font-size: 20px;
		}
		@include respond-to('extra-small') {
			// margin-top: 10px;
			// font-size: 18px;
		}
	}
}