.wrap-content {
	position: relative;
	padding: 0;
	box-shadow: inset 0 30px 55px -20px rgba(0, 0, 0, 0.2);
	&_cont {
		padding-bottom: 140px;
		@include respond-to('medium') {
			padding-bottom: 100px;
		}
		@include respond-to('small') {
			padding-bottom: 50px;
		}
		@include respond-to('extra-small') {
			padding-bottom: 20px;
		}
	}
	&_catalog {
		padding-bottom: 70px;
		@include respond-to('medium') {
			padding-bottom: 50px;
		}
		@include respond-to('small') {
			padding-bottom: 35px;
		}
	}
	& .section-limit {
		&_pos-r {
			position: relative;
			z-index: 1;
		}
	}
}

.cont-decor-top {
	position: absolute;
	#{$left}: -50px;
	top: 155px;
	// width: 22vw;
	z-index: auto;
	@include respond-to('1550') {
		opacity: 0.7;
		#{$left}: -100px;
	}
	@include respond-to('large') {
		min-width: 292px;
	}
	@include respond-to('small') {
		#{$left}: -150px;
		top: 100px;
	}
	@include respond-to('extra-small') {
		#{$left}: -100px;
		top: 130px;
	}
}

.padding-top-catalog-item {
	margin-top: 180px;
	@include respond-to('medium') {
		margin-top: 80px;
	}
}

.title {
	margin: 0;
	text-align: center;
	color: $title;
	font-size: $font-size-large;
	font-weight: 800;
	line-height: 0.9;
	@include respond-to('small') {
		font-size: $font-size-medium;
	}
	@include respond-to('extra-small') {
		font-size: 22px;
	}
	&_white {
		color: #fff;
	}
	&_mt {
		margin-top: 15px;
		@include respond-to('1100') {
			margin-top: 5px;
		}
		@include respond-to('small') {
			margin-top: 0;
			padding-top: 20px;
		}
	}
	&_mb {
		margin-bottom: 60px;
		@include respond-to('larger') {
			margin-bottom: 30px;
		}
		@include respond-to('1100') {
			margin-bottom: 15px;
		}
	}
	&_ta-right {
		margin-bottom: 30px;
		text-align: $right;
	}
}

.subtitle {
	display: block;
	text-align: center;
	font-size: $font-size-medium;
	font-weight: 400;
	line-height: 0.9;
	color: $title;
	@include respond-to('small') {
		font-size: $font-size-base;
	}
	&_mb {
		margin-bottom: 60px;
		@include respond-to('larger') {
			margin-bottom: 30px;
		}
		@include respond-to('1100') {
			margin-bottom: 15px;
		}
	}
	&_mt {
		margin-top: 15px;
		@include respond-to('1100') {
			margin-top: 5px;
		}
		@include respond-to('medium') {
			margin-top: 0;
			padding-top: 15px;
		}
	}
}

.description {
	max-width: 1150px;
	width: 100%;
	margin: 0 auto;
	text-align: center;
	& p {
		font-size: $font-size-base;
		font-weight: 400;
		color: $text;
		@include respond-to('small') {
			font-size: 18px;
		}
		@include respond-to('extra-small') {
			font-size: 16px;
			text-align: justify;
		}
	}
}

.link {
	display: inline-block;
	padding-bottom: 8px;
	border-bottom: 2px solid $accent-g;
	font-size: 20px;
	font-weight: 800;
	// color: #000;
	@include link_no-hover(#000);
	@include respond-to('small') {
		font-size: 18px;
	}
}

.hr-content {
	border-top: 1px solid #d9d9d9;
	margin: 0 0 20px;
}

.content-text {
	h2,
	h3 {
		font-size: 25px;
		font-weight: 400;
		line-height: 1.2;
		color: #000;
		margin: 20px 0 20px;
		@include respond-to('large') {
			font-size: 22px;
		}
		@include respond-to('extra-small') {
			font-size: 20px;
		}
	}
	ul {
		padding-#{$right}: 0;
		list-style: none;
	}
	li {
		position: relative;
		padding-#{$right}: 20px;
		&::after {
			position: absolute;
			#{$right}: 0;
			top: 1px;
			content: '\25CF';
			color: $accent;
			font-size: 18px;
		}
	}
	p,
	li {
		font-size: $font-size-base;
		font-weight: 400;
		color: $text;
		@include respond-to('small') {
			font-size: 18px;
		}
		@include respond-to('extra-small') {
			font-size: 16px;
			text-align: justify;
		}
	}
	iframe {
		@include respond-to('small') {
			text-align: center !important;
			margin: 15px auto !important;
			width: 100% !important;
			height: auto !important;
			float: none !important;
			display: block !important;
		}
	}
	img {
		@include respond-to('small') {
			float: none !important;
			text-align: center !important;
			width: auto !important;
			height: auto !important;
			margin: 15px auto !important;
		}
	}
}