.horizontal-form {
	position: relative;
	z-index: 1;
	padding: 25px 0;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.15);
	& .msg-success {
		color: #fff;
	}
	& .help-block {
		color: #fff;
	}
	& .help-block {
		font-size: 16px;
	}
	& .form-control:focus {
		border-radius: 0;
	}
	& .ftr-check-uncheck {
		position: relative;
		display: inline-block;
		width: 23px;
		height: 23px;
		margin-#{$left}: 5px;
		margin-top: 5px;
		vertical-align: top;
		label {
			width: 23px;
			height: 23px;
			cursor: pointer;
			position: absolute;
			background-color: #eeeeee;
			border: 1px solid #bebebe;
			#{$left}: 0;
			top: 0;
			&::after {
				content: url(../images/check.png);
				position: absolute;
				top: -3px;
				#{$left}: 3px;
				opacity: 0;
				@include respond-to('larger') {
					top: 3px;
				}
			}
		}
		input[type=checkbox] {
			visibility: hidden;
			&:checked + label::after {
				opacity: 1;
			}
		}
		&_side {
			margin-top: 20px;
			label::after {
				top: -2px;
			}
		}
	}
	& .ftr-checkbox-para {
		display: inline-block;
		max-width: 75px;
		@include respond-to('larger') {
			max-width: 150px;
		}
		& > p {
			font-size: 14px;
			font-weight: 300;
			color: #000;
			margin: 0;
			@include respond-to('extra-small') {
				font-size: 13px;
			}
		}
		&_side {
			margin-top: 15px;
			max-width: 128px;
		}
	}
}

.section-limit {
	&_form {
		max-width: 1400px;
		width: 100%;
	}
}

.form-horizontal {
	padding: 20px 90px;
	background-color: rgba($color: #000, $alpha: 0.6);
	box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.15);
	@include respond-to('huge') {
		padding: 20px 50px;
	}
	@include respond-to('larger') {
		padding: 20px 30px;
	}
	@include respond-to('small') {
		padding: 15px;
	}
	& .contact-us-form {
		overflow: hidden;
	}
	& .contact-us-form__title {
		margin-bottom: 20px;
		text-align: center;
		font-size: $font-size-medium;
		font-weight: 600;
		color: #fff;
		line-height: 1;
		@include respond-to('1100') {
			font-size: 20px;
		}
		& span {
			color: $accent-g;
			font-weight: 800;
		}
	}
	& .contact-wrap-input {
		@include respond-to('larger') {
			font-size: 0;
			text-align: center;
		}
	}
	& .form-group {
		float: $right;
		vertical-align: top;
		margin: 0 0 0 20px;
		max-width: 230px;
		width: 100%;
		@include respond-to('larger') {
			max-width: 25%;
			float: none;
			display: inline-block;
			margin: 0;
			padding: 0 8px;
		}
		@include respond-to('1100') {
			max-width: 25%;
		}
		@include respond-to('medium') {
			max-width: 50%;
		}
		@include respond-to('extra-small') {
			padding: 0 4px;
			max-width: 100%;
		}
		&_area {
			max-width: 260px;
			@include respond-to('larger') {
				max-width: 23%;
			}
			@include respond-to('medium') {
				max-width: 50%;
			}
			@include respond-to('extra-small') {
				max-width: 100%;
			}
		}
		&_check {
			max-width: 108px;
			@include respond-to('larger') {
				max-width: 40%;
				margin: 0;
			}
			@include respond-to('extra-small') {
				margin-top: 2px;
				max-width: 44%;
			}
		}
		&_ml {
			margin-#{$left}: 0;
		}
		& textarea {
			height: 50px;
			padding: 11px 10px 0;
			max-width: 260px;
			@include respond-to('larger') {
				max-width: 100%;
				resize: none;
			}
			@include respond-to('medium') {
				max-width: 100%;
			}
		}
		&_button {
			max-width: 130px;
			float: $left;
			margin: 0;
			@include respond-to('huge') {
				max-width: 125px;
			}
			@include respond-to('larger') {
				max-width: 40%;
				float: none;
			}
			@include respond-to('extra-small') {
				margin-top: 10px;
				max-width: 44%;
			}
		}
	}
	& .newslatter-input {
		padding: 13px 10px 14px;
		width: 100%;
		height: 100%;
		max-height: 50px;
		background-color: #fff;
		color: #777;
		font-weight: 300;
		box-shadow: inset 0px 0px 10px 5px rgba($color: #f8f8f8, $alpha: 0.8);
		font-size: 18px;
		border: none;
		border-radius: 0;
		&::placeholder {
			font-size: 18px;
			color: #777;
		}
	}
	& .newslatter-btn {
		width: 100%;
		margin: 0;
		height: 50px;
		font-size: $font-size-medium;
		font-weight: 700;
		background-color: $accent-g;
		border: none;
		color: #fff;
		&:active {
			@include btn-effect-active;
		}
	}
}