.cat-top-decor {
	position: absolute;
	top: -70px;
	right: -50px;
	width: 16vw;
	@include respond-to('1550') {
		top: 50px;
		right: -80px;
	}
	@include respond-to('huge') {
		right: -120px;
	}
	@include respond-to('large') {
		display: none;
	}
}

.product-description-wrap {
	padding-top: 40px;
	@include respond-to('large') {
		padding-top: 20px;
	}
}

.product-item-description {
	&__text {
		margin-bottom: 30px;
		padding-#{$left}: 70px;
		font-size: 20px;
		font-weight: 400;
		color: #000;
		@include respond-to('huge') {
			padding-#{$left}: 40px;
		}
		@include respond-to('large') {
			padding-#{$left}: 15px;
		}
		@include respond-to('medium') {
			padding-#{$left}: 0;
			margin-bottom: 20px;
		}
		@include respond-to('small') {
			font-size: 18px;
			text-align: justify;
		}
		@include respond-to('extra-small') {
			font-size: 16px;
		}
	}
	&__title {
		font-size: 25px;
		font-weight: 800;
		line-height: 0.9;
	}
	&__subtitle {
		font-size: 20px;
		font-weight: 400;
		line-height: 0.9;
	}
	&__prise-block {
		// margin-bottom: 40px;
		@include respond-to('large') {
			// margin-bottom: 20px;
		}
		& .old-price {
			&::before {
				width: 100%;
			}
		}
	}
	&__cart-block {
		float: $right;
		max-width: 345px;
		width: 100%;
		@include respond-to('extra-small') {
			float: none;
		}
	}
}

.cart-button {
	position: relative;
	display: inline-block;
	text-align: center;
	max-width: 345px;
	width: 100%;
	margin-#{$right}: 0;
	margin-top: 20px;
	padding: 20px 10px;
	border: none;
	background-color: $accent-g;
	color: #fff;
	font-size: 35px;
	font-weight: 800;
	transition: box-shadow 0.2s ease;
	box-shadow: 0px 5px 10px 5px rgba($color: #000000, $alpha: 0.2), inset 0px 5px 10px 0 rgba($color: #fff, $alpha: 0.5), inset 0px -5px 10px 0px rgba(0, 0, 0, 0.2);
	@include link_no-hover(#fff);
	@include respond-to('large') {
		padding: 15px 20px;
	}
	@include respond-to('small') {
		// padding: 7px 15px;
		font-size: 25px;
	}
	@include respond-to('extra-small') {
		margin: 20px auto 0;
	}
	&:active,
	&:focus {
		@include btn-effect-active;
		box-shadow: 0px 5px 10px 3px rgba($color: #000000, $alpha: 0.2), inset 0px 5px 5px 0 rgba($color: #fff, $alpha: 0.5), inset 0px -5px 5px 0px rgba(0, 0, 0, 0.2);
	}
	& i {
		margin-#{$left}: 15px;
	}
	&_cart {
		&::after {
			content: '';
			position: absolute;
			#{$right}: 93px;
			top: 50%;
			transform: translateY(-50%);
			height: 70%;
			width: 1px;
			background-color: #fff;
		}
	}
	&__icon {
		// display: inline-block;
		float: right;
		position: relative;
		top: 3px;
		height: 29px;
		width: 32px;
		margin-#{$left}: 30px;
		margin-top: 8px;
		margin-right: 20px;
		background-image: url(../images/cart-icon-white.png);
		background-position: center;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		@include respond-to('small') {
			margin-top: 0px;
		}
	}
}

.old-price {
	position: relative;
	font-size: $font-size-base;
	color: #5c5c5c;
	&::before {
		content: '';
		position: absolute;
		top: 50%;
		right: 0;
		width: 93%;
		height: 1px;
		background-color: #5c5c5c;
	}
}

.new-price {
	margin: 0;
	font-size: 30px;
	font-weight: 400;
	line-height: 1;
	color: #5c5c5c;
	@include respond-to('small') {
		font-size: 25px;
	}
	& .new-value {
		color: #000;
		font-weight: 400;
	}
}

.product-item-gallery-block {
	position: relative;
	text-align: $left;
	@include respond-to('medium') {
		text-align: center;
		margin: 0 auto 15px;
		max-width: 450px;
	}
}

.product-item-gallery {
	padding: 35px;
	float: left;
	position: relative;
	border-radius: 6px;
	border: 1px solid #dedede;
	overflow: hidden;
	text-align: center;
	@include respond-to('medium') {
		float: none;
		display: block;
	}
	& img {
		display: block;
		max-width: 100%;
		margin: 0 auto;
	}
}

.hr-item {
	margin: 80px 0 0;
	padding: 0;
	border-top: 1px solid #dedede;
	@include respond-to('medium') {
		margin: 40px 0 0;
	}
	@include respond-to('small') {
		margin: 30px 0 0;
	}
	@include respond-to('extra-small') {
		margin: 20px 0 0;
	}
}

.other-propositions {
	margin: 30px 0 55px;
	padding-bottom: 15px;
	border-bottom: 1px solid #898989;
	font-size: 30px;
	font-weight: 800;
	color: #000;
	@include respond-to('medium') {
		margin: 25px 0;
		padding-bottom: 20px;
	}
	@include respond-to('small') {
		margin: 15px 0;
		font-size: 25px;
		font-weight: 600;
		padding-bottom: 15px;
	}
	@include respond-to('extra-small') {
		font-size: 22px;
	}
}

.hp-projects-items {
	margin: 0 -8px;
	font-size: 0;
	@include respond-to('small') {
		text-align: center;
	}
	&__item {
		// float: $right;
		display: inline-block;
		width: 33.33%;
		height: 281px;
		padding: 8px;
		@include respond-to('small') {
			width: 50%;
			height: 250px;
		}
		@include respond-to('extra-small') {
			width: 100%;
			// max-width: 340px;
		}
	}
}