.cat-boxes {
	margin: 0 -8px;
	@include respond-to('medium') {
		margin: 0 -4px;
	}
	&__item {
		float: $right;
		width: 33.33%;
		height: 281px;
		padding: 8px;
		&:nth-child(8n + 5) {
			float: $left;
		}
		@include respond-to('medium') {
			height: 240px;
			padding: 4px;
		}
		@include respond-to('small') {
			width: 50%;
		}
		@include respond-to('extra-small') {
			width: 100%;
			max-width: 380px;
			margin: 0 auto;
			float: none;
		}
		&_hight {
			height: 562px;
			@include respond-to('medium') {
				height: 480px;
			}
			@include respond-to('small') {
				height: 240px;
			}
		}
		&_long {
			width: 66.66%;
			@include respond-to('small') {
				width: 50%;
			}
			@include respond-to('extra-small') {
				width: 100%;
			}
		}
	}
}

.cat-boxes2 {
	margin: 0 -8px;
	@include respond-to('medium') {
		margin: 0 -4px;
	}
	&__item2 {
		float: $right;
		width: 33.33%;
		height: 281px;
		padding: 8px;
		&:nth-child(8n + 5) {
			float: $left;
		}
		@include respond-to('medium') {
			height: 240px;
			padding: 4px;
		}
		@include respond-to('small') {
			width: 50%;
		}
		@include respond-to('extra-small') {
			width: 100%;
			max-width: 380px;
			margin: 0 auto;
			float: none;
		}
	}
}