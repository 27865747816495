.wrapper-all-content {
	background-color: #fff;
	position: relative;
	width: 100%;
}

.sec-padd {
	padding: 65px 0 70px;
	@include respond-to('1100') {
		padding: 45px 0 50px;
	}
	@include respond-to('small') {
		padding: 25px 0;
	}
}

.hp-about {
	text-align: center;
	box-shadow: inset 0px 20px 50px -10px rgba(0, 0, 0, 0.1);
	&__title {
		margin-bottom: 25px;
		@include respond-to('small') {
			margin-bottom: 15px;
		}
	}
	&__subtitle {}
	&__desc {
		max-width: 830px;
		width: 100%;
		& p {
			margin: 0;
		}
	}
	&__link {
		margin-top: 30px;
		@include respond-to('small') {
			margin-top: 15px;
		}
	}
	& .section-limit {
		position: relative;
		z-index: 2;
	}
}

.hp-top-decor {
	pointer-events: none;
	position: absolute;
	top: -115px;
	right: -55px;
	z-index: 1;
	@include respond-to('1750') {
		max-width: 30vw;
		top: -50px;
		right: -55px;
	}
	@include respond-to('1550') {
		max-width: 25vw;
		top: -20px;
		right: -55px;
	}
	@include respond-to('huge') {
		opacity: 0.8;
		min-width: 300px;
		width: 25vw;
	}
	@include respond-to('small') {
		display: none;
	}
}

.hp-middle-decor {
	pointer-events: none;
	position: absolute;
	bottom: -142px;
	left: -250px;
	z-index: 0;
	@include respond-to('1550') {
		left: -350px;
	}
	@include respond-to('larger') {
		left: -430px;
	}
	@include respond-to('medium') {
		max-width: 450px;
		left: -300px;
		bottom: -85px;
	}
	@include respond-to('small') {
		display: none;
	}
}

.hp-cat-sec {
	position: relative;
	background-color: #e9e9e9;
	& .section-limit {
		position: relative;
		z-index: 1;
	}
}

.hp-hr {
	margin: 35px 0;
	border-top: 2px solid #e9e9e9;
	@include respond-to('medium') {
		margin: 20px 0;
	}
	&_white {
		border-top: 2px solid #fff;
	}
}

.hp-cat-boxes {
	margin: 0 -8px;
	padding-top: 30px;
	@include respond-to('medium') {
		margin: 0 -4px;
	}
	&__item {
		float: $right;
		width: 33.33%;
		height: 281px;
		padding: 8px;
		@include respond-to('medium') {
			height: 240px;
			padding: 4px;
		}
		@include respond-to('small') {
			width: 50%;
		}
		@include respond-to('extra-small') {
			width: 100%;
			max-width: 380px;
			margin: 0 auto;
			float: none;
		}
		&_hight {
			height: 562px;
			@include respond-to('medium') {
				height: 480px;
			}
			@include respond-to('small') {
				height: 240px;
			}
		}
		&_long {
			width: 66.66%;
			@include respond-to('small') {
				width: 50%;
			}
			@include respond-to('extra-small') {
				width: 100%;
			}
		}
	}
	&__link {
		margin-top: 35px;
		text-align: center;
		& a {
			padding-bottom: 6px;
			border-bottom: 2px solid $accent-g;
			font-size: 20px;
			font-weight: 800;
			@include link_no-hover(#000);
		}
	}
}

.prod-sec-desc {
	max-width: 1070px;
	margin: 0 auto;
	text-align: center;
	& p {
		font-size: 20px;
		font-weight: 400;
		color: #000;
		@include respond-to('small') {
			font-size: 18px;
		}
		@include respond-to('extra-small') {
			font-size: 16px;
			text-align: justify;
		}
	}
}

.prod-sec-sliders {
	margin: 0 -20px;
	padding: 40px 0;
	@include respond-to('medium') {
		padding: 20px 0;
		margin: 0 -15px;
	}
	@include respond-to('small') {
		padding: 10px 0;
	}
}

.prod-sec-slider {
	& .slick-list {
		padding: 25px 0 !important;
	}
	& .slick-dots {
		position: absolute;
		bottom: -60px;
		#{$left}: 0;
		text-align: center;
		@include respond-to('medium') {
			bottom: -45px;
		}
		@include respond-to('small') {
			bottom: -30px;
		}
		& li {
			margin-#{$left}: 0;
			& button {
				height: 5px;
				width: 16px;
				padding: 0;
				&::before {
					opacity: 1;
					content: '';
					background-color: #d0d0d0;
					height: 5px;
					width: 16px;
					@include respond-to('mobile-l') {
						height: 5px;
						width: 12px;
					}
				}
			}
			&.slick-active {
				& button {
					&::before {
						background-color: $accent-g;
					}
				}
			}
		}
	}
	&__item {
		margin: 10px 28px;
		height: 288px;
		transition: transform 0.4s ease;
		@include respond-to('1100') {
			margin: 10px 15px;
		}
		@include respond-to('medium') {
			margin: 10px;
			height: 260px;
		}
		@include respond-to('small') {
			height: 250px;
			max-width: 388px;
		}
		@include respond-to('extra-small') {
			margin: 0 50px;
		}
		@include respond-to('mobile-l') {
			margin: 0 30px;
		}
		&.slick-center.slick-current {
			// outline: 1px solid red;
			transform: scale(1.2, 1.1);
			@include respond-to('large') {
				transform: scale(1.1, 1.05);
			}
			@include respond-to('medium') {
				transform: scale(1);
			}
			& .homepage-box-info {
				background-color: rgba($color: $accent, $alpha: 0.8);
				padding: 10px;
				@include respond-to('small') {
					padding: 5px;
				}
				&__title {
					color: #fff;
				}
				&__desc {
					display: block;
				}
			}
			&.prod-sec-slider__item {
				position: relative;
				height: 300px;
				// position: absolute;
				// width: 450px !important;
				// margin: 10px;
				@include respond-to('large') {
					height: 288px;
				}
				@include respond-to('medium') {
					height: 260px;
				}
				@include respond-to('small') {
					height: 250px;
				}
				& .homepage-box {
					position: absolute;
					top: 0;
					left: 50%;
					transform: translateX(-50%);
					// width: 400px !important;
					@include respond-to('large') {
						// width: none !important;
					}
				}
			}
		}
	}
}