.wrapper-prod-gallery {
	width: 100%;
	position: relative;
	@include respond-to('large') {
		margin: 0 auto 20px;
	}
	@include respond-to('medium') {
		margin-bottom: 15px;
	}
	@include respond-to('small') {
		height: auto;
	}
	&_padding {
		@include respond-to('large') {
			max-width: 592px;
		}
	}
}

.prod-gallery {
	position: relative;
	width: 100%;
	// height: 580px;
	border: 2px solid $accent;
	@include respond-to('small') {
		height: auto;
	}
	& .slick-next {
		left: 0;
	}
	& .slick-prev {
		right: 0;
	}
	&__helper-wrap {
		position: relative;
		// height: 550px;
		height: auto;
		padding: 6px;
		// border: 1px solid $accent;
		@include respond-to('mobile-l') {
			padding: 1px;
		}
	}
	&__img-big {
		&_youtube {
			&::before {
				display: none;
				font-family: "Font Awesome 5 Brands";
				content: "\f167";
			}
			&.svg-inline--fa {
				margin-left: 0.25em;
				font-size: 2em;
				vertical-align: middle;
			}
			& .fa-youtube {
				color: $accent;
				font-size: 60px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 2;
				cursor: pointer;
			}
		}
		&:hover {
			cursor: zoom-in;
		}
		img {
			border: 10px solid $accent;
			@include respond-to('mobile-l') {
				border-width: 3px;
			}
		}
	}
}

.gallery-zoom {
	pointer-events: none;
	position: absolute;
	left: 16px;
	bottom: 16px;
	@include respond-to('mobile-l') {
		left: 4px;
		bottom: 4px;
	}
}

.prod-gallery-navi {
	position: relative;
	width: 100%;
	margin-top: 6px;
	padding: 0;
	.slick-list {
		padding: 1px;
		margin: 0 -8px;
		@include respond-to('huge') {
			margin: 0 -6px;
		}
	}
	&__img-small {
		position: relative;
		padding: 8px;
		max-width: 154px;
		height: 142px !important;
		border: 1px solid transparent !important;
		cursor: pointer;
		transition: all 0.4s;
		@include respond-to('1550') {
			height: 125px !important;
		}
		@include respond-to('huge') {
			padding: 6px;
		}
		@include respond-to('small') {
			max-width: 190px;
		}
		@include respond-to('mobile-m') {
			// width: 50% !important;
		}
		&.slick-current {
			& .prod-gallery-navi__img-small_padding {
				outline: 2px solid $accent-y;
				box-shadow: 0 0 12px 3px rgba($color: #000000, $alpha: 0.3);
			}
		}
		&:hover {}
		&:focus {
			outline: none;
		}
		&_padding {
			position: relative;
			overflow: hidden;
			width: 100%;
			height: 100%;
			transition: box-shadow 0.2s ease, outline 0.2s ease;
			& > img {
				max-width: 100%;
				width: 100%;
				height: 100%;
				// max-height: 100%;
				// position: absolute;
				// top: 0;
				// right: 0;
				// bottom: 0;
				// left: 0;
				margin: auto;
			}
		}
		&_youtube {
			&::before {
				font-family: "Font Awesome 5 Brands";
				content: "\f167";
				color: $clr-youtube;
				font-size: 32px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 2;
				cursor: pointer;
			}
		}
	}
}

#prod-gallery {
	.prod-gallery__helper-wrap {
		display: none;
	}
	.prod-gallery__helper-wrap:first-child,
	.prod-gallery__helper-wrap.slick-slide {
		display: block;
	}
}

#prod-gallery-navi {
	.prod-gallery-navi__img-small {
		display: none;
	}
	.prod-gallery-navi__img-small:first-child,
	.prod-gallery-navi__img-small.slick-slide {
		display: block;
	}
}