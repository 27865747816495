.wrapper-banners {
	position: relative;
	width: 100%;
	height: 100vh;
	max-height: 950px;
	overflow: hidden;
	@include respond-to('medium') {
		max-height: unset;
		height: auto;
	}
	// @include respond-to('huge') {
	// 	height: 650px;
	// }
	// @include respond-to('large') {
	// 	height: 450px;
	// }
	// @include respond-to('extra-small') {
	// 	height: 300px;
	// }
}

.wrap-banner-scroll {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include respond-to('medium') {
		position: static;
  }
  @include respond-to('extra-small') {
		height: 300px;
	}
}

.banner-item {
	width: 100%;
	min-height: 100vh;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	// @include respond-to('huge') {
	// 	min-height: 650px;
	// }
	// @include respond-to('large') {
	// 	min-height: 450px;
	// }
	@include respond-to('extra-small') {
		min-height: 300px;
	}
	& .section-limit {
		// position: relative;
    height: 100vh;
    @include respond-to('extra-small') {
      height: 300px;
    }
	}
}

.banner-item-shadow {
	position: absolute;
	right: 0;
	top: 0;
	height: 100vh;
	width: 1200px;
	background-image: url('../images/shadow-banner.png');
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	@include respond-to('large') {
		right: -300px;
		// width: 200%;
	}
	@include respond-to('extra-small') {
    right: -400px;
    height: 300px;
  }
}

.shadow-text-wrap {
	position: relative;
	height: 100%;
	width: 100%;
}

.shadow-text {
	position: absolute;
	top: 40%;
	#{$right}: 29%;
	max-width: 450px;
	width: 100%;
	@include respond-to('large') {
		#{$right}: 320px;
	}
	@include respond-to('medium') {
		top: 40%;
	}
	@include respond-to('extra-small') {
		max-width: 290px;
		#{$right}: 420px;
	}
	&__title {
		color: $accent;
		font-size: 70px;
		font-weight: 800;
		line-height: 0.8;
		@include respond-to('small') {
			font-size: 50px;
		}
		@include respond-to('extra-small') {
			font-size: 40px;
		}
	}
	&__subtitle {
		color: $accent-y;
		font-size: 60px;
		font-weight: 300;
		line-height: 0.9;
		@include respond-to('small') {
			font-size: 40px;
		}
		@include respond-to('small') {
			font-size: 30px;
		}
	}
}

.banner-nav {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 1;
	@include respond-to('medium') {
		position: static;
	}
}