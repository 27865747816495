.wrap-cat-box {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	@include respond-to('medium') {}
	&::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: "";
		transition: all 0.3s;
		opacity: 0;
		background-color: rgba($color: $accent, $alpha: 0.7);
	}
	&:hover {
		&::after {
			opacity: 1;
		}
		& .cat-box {
			visibility: visible;
			opacity: 1;
		}
	}
}

.cat-box {
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 50%;
	visibility: hidden;
	width: 100%;
	padding: 0 25px;
	transition: opacity 0.3s ease, visibility 0.3s ease;
	transform: translate(-50%, -50%);
	opacity: 0;
	&__title {
		text-align: center;
		color: #ffffff;
		font-size: 20px;
		font-weight: 800;
		@include respond-to('medium') {
			font-size: 18px;
		}
	}
	&__desc,
	&__desc p {
		margin: 0;
		padding-top: 5px;
		text-align: center;
		color: #ffffff;
		font-size: 18px;
		font-weight: 400;
		line-height: 1.2;
		@include respond-to('medium') {
			font-size: 16px;
		}
	}
}